<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <!-- Dialogo de cambio de contraseña -->
    <v-dialog v-model="changePwd" persistent max-width="480">
      <v-card>
        <v-card-title>
          <span class="headline">Cambio de contraseña</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="$v.password.$model"
                  label="Contraseña*"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  :counter="15"
                  required
                  :error-messages="passwordErrors"
                  @click:append.prevent="show1 = !show1"
                  @input="$v.newPassword.$reset()"
                  @blur="$v.newPassword.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="$v.newPassword.$model"
                  label="Nueva contraseña*"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  :counter="15"
                  required
                  :error-messages="newPasswordErrors"
                  @click:append.prevent="show2 = !show2"
                  @input="$v.newPassword.$reset()"
                  @blur="$v.newPassword.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="$v.confirmNewPassword.$model"
                  label="Confirmar Nueva contraseña*"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show3 ? 'text' : 'password'"
                  :counter="15"
                  required
                  :error-messages="confirmNewPasswordErrors"
                  @click:append.prevent="show3 = !show3"
                  @input="$v.newPassword.$reset()"
                  @blur="$v.newPassword.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*Campo Obligatorio</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeForm">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="changePassword" :disabled="$v.$invalid"
            >Cambiar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Botón para contraer el drawer -->
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">mdi-view-quilt</v-icon>

      <v-icon v-else>mdi-dots-vertical</v-icon>
    </v-btn>

    <!-- Titulo o mensaje desplegado -->
    <!-- <v-toolbar-title class="hidden-sm-and-down font-weight-light" v-text="$route.name" /> -->

    <v-spacer />

    <!-- Boton para el dashboard -->
    <v-btn class="ml-2" min-width="0" text @click="dashboard" :disabled="$route.path === '/'">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <!-- Menú Badges -->
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <!-- Menú Configuracion -->
    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
      v-if="isAdminRole"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>

      <v-list dense :tile="false" nav>
        <!-- <v-subheader>Administración</v-subheader> -->
        <!-- Sub Menú Almacén -->
        <v-list-item-group>
          <v-list-item @click="almacenSettings">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-storefront</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Administración de Almacén</v-list-item-title>
          </v-list-item>

          <!-- Sub Menú Aplicaciones -->
          <v-list-item @click="aplicacionSettings">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-apps-box</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Administración de Aplicaciones</v-list-item-title>
          </v-list-item>

          <!-- Sub Menú Catálogos -->
          <v-list-item @click="catalogoSettings">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-bookshelf</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Administración de Catálogos</v-list-item-title>
          </v-list-item>

          <!-- Sub Menú Tarifas -->
          <v-list-item @click="tarifasSettings">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-currency-usd</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Administración de Tarifas</v-list-item-title>
          </v-list-item>

          <!-- Sub Menú Transportadora -->
          <v-list-item @click="transportadoraSettings">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-train-car</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Administración de Transportadora</v-list-item-title>
          </v-list-item>

          <!-- Sub Menú Usuarios -->
          <v-list-item @click="usuariosSettings">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Administración de Usuarios</v-list-item-title>
          </v-list-item>

          <!-- Sub Menú Ventas -->
          <v-list-item @click="ventasSettings">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-cash-register</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Administración de Ventas</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <!-- Menu usuario -->
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list dense :tile="false" nav>
        <!-- Sub Menú Perfil -->
        <v-list-item-group>
          <v-list-item @click="userProfile">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Mi Perfil</v-list-item-title>
          </v-list-item>

          <!-- Sub Menú Cambiar Contraseña -->
          <v-list-item @click="changePwd = true">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-lock-reset</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cambiar Contraseña</v-list-item-title>
          </v-list-item>

          <v-divider class="mb-2 mt-2" />

          <!-- Sub Menú Cerrar Sesión -->
          <v-list-item @click="userLogout">
            <v-list-item-icon class="mr-2" @click="userLogout">
              <v-icon>mdi-power</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cerrar Sesión</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <!-- Empiezan tabs de subsistemas de administración -->
    <template v-if="adminMode" v-slot:extension>
      <v-tabs align-with-title v-model="tab">
        <v-tab key="almacen" @click="almacenSettings">Almacén</v-tab>
        <v-tab key="aplicaciones" @click="aplicacionSettings">Aplicaciones</v-tab>
        <v-tab key="catalogos" @click="catalogoSettings">Catálogos</v-tab>
        <v-tab key="tarifas" @click="tarifasSettings">Tarifas</v-tab>
        <v-tab key="tranportadora" @click="transportadoraSettings">Transportadora</v-tab>
        <v-tab key="usuarios" @click="usuariosSettings">Usuarios</v-tab>
        <v-tab key="ventas" @click="ventasSettings">Ventas</v-tab>
        <!-- <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab> -->
      </v-tabs>
    </template>
    <!-- Terminan tabs de subsistemas de administración -->
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from 'vuetify/lib'

// Utilities
import { mapState, mapMutations } from 'vuex'

import { required, minLength, maxLength, sameAs, not } from 'vuelidate/lib/validators'

const oneNumber = (value) => /[0-9]/.test(value)
const oneUpperCase = (value) => /[A-Z]/.test(value)
const oneLowerCase = (value) => /[a-z]/.test(value)

export default {
  name: 'DashboardCoreAppBar',
  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              )
            },
          },
        })
      },
    },
  },
  data: () => ({
    changePwd: false,
    confirmNewPassword: '',
    items: ['Almacén', 'Aplicaciones', 'Catálogos', 'Tarifas', 'Usuarios', 'Ventas'],
    newPassword: '',
    notifications: [
      'Nueva reserva',
      'La reserva n87wol487eoq7djw03id76wn ha sido atendida',
      'Reserva cancelada',
      'Reserva modificada',
      'Nueva reserva',
    ],
    password: '',
    show1: false,
    show2: false,
    show3: false,
    // tab: null,
    user: JSON.parse(localStorage.getItem('user')),
  }),

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  validations: {
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(15),
      oneNumber,
      oneUpperCase,
      oneLowerCase,
    },
    newPassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(15),
      oneNumber,
      oneUpperCase,
      oneLowerCase,
      notSameAsPassword: not(sameAs('password')),
    },
    confirmNewPassword: {
      sameAsNewPassword: sameAs('newPassword'),
    },
  },

  computed: {
    ...mapState(['adminMode', 'drawer', 'auth/user']),

    confirmNewPasswordErrors() {
      const errors = []
      if (!this.$v.confirmNewPassword.$dirty) return errors
      !this.$v.confirmNewPassword.sameAsNewPassword && errors.push('La contraseña debe ser igual.')
      return errors
    },

    isAdminRole() {
      return this.user.roles.includes('Administrador') ? true : false
    },

    newPasswordErrors() {
      const errors = []
      if (!this.$v.newPassword.$dirty) return errors
      !this.$v.newPassword.minLength &&
        errors.push('La nueva contraseña debe de tener un mínimo de 6 caracteres.')
      !this.$v.newPassword.maxLength &&
        errors.push('La nueva contraseña debe de tener un máximo de 15 caracteres.')
      !this.$v.newPassword.required && errors.push('La nueva contraseña es requerida.')
      !this.$v.newPassword.oneNumber &&
        errors.push('La nueva contraseña debe de tener al menos un número')
      !this.$v.newPassword.oneUpperCase &&
        errors.push('La nueva contraseña debe de tener al menos una letra mayúscula')
      !this.$v.newPassword.oneLowerCase &&
        errors.push('La nueva contraseña debe de tener al menos una letra minúscula')
      !this.$v.newPassword.notSameAsPassword &&
        errors.push('La nueva contraseña no puede ser igual a la actual')
      return errors
    },

    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.minLength && errors.push('Contraseña Incorrecta.')
      !this.$v.password.maxLength && errors.push('Contraseña Incorrecta.')
      !this.$v.password.required && errors.push('Contraseña Incorrecta.')
      !this.$v.password.oneNumber && errors.push('Contraseña Incorrecta.')
      !this.$v.password.oneUpperCase && errors.push('Contraseña Incorrecta.')
      !this.$v.password.oneLowerCase && errors.push('Contraseña Incorrecta.')
      return errors
    },

    tab: {
      get() {
        return this.$store.state.tab
      },
      set(value) {
        this.$store.commit('SET_MENU_TAB', value)
      },
    },
  },

  methods: {
    /**
     * @description Establece el modo de administrador en falso, y dashboard
     * como menú actual asi como el router en la raiz
     */
    dashboard() {
      this.setAdminMode(false)
      this.$store.commit('SET_MENU', 'dashboard')
      this.$router.push('/')
    },

    /**
     * @description Establece el modo de administrador en verdadero, y almacén
     * como menú actual asi como el router en extras y la pestaña del tab en 0
     */
    almacenSettings() {
      this.$store.commit('SET_MENU', 'almacen')
      this.$router.push({ name: 'Extras' })
      this.setAdminMode(true)
      this.tab = 0
    },

    /**
     * @description Establece el modo de administrador en verdadero, y aplicaciones
     * como menú actual asi como el router en sistemas y la pestaña del tab en 1
     */
    aplicacionSettings() {
      this.$store.commit('SET_MENU', 'aplicaciones')
      this.$router.push({ name: 'Sistemas' })
      this.setAdminMode(true)
      this.tab = 1
    },

    /**
     * @description Establece el modo de administrador en verdadero, y catálogos
     * como menú actual asi como el router en géneros y la pestaña de tab en 2
     */
    catalogoSettings() {
      this.$store.commit('SET_MENU', 'catalogos')
      this.$router.push({ name: 'Géneros' })
      this.setAdminMode(true)
      this.tab = 2
    },

    /**
     * @description Cambia la contraseña del usuario activo
     */
    changePassword() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$store
          .dispatch('auth/changePassword', {
            id: this.user.id,
            password: this.password,
            new_password: this.newPassword,
          })
          .then(() => {
            console.log('Entró')
            this.$store.commit('ALERT', {
              color: 'success',
              text: 'La contraseña se cambió con éxito',
            })
            this.closeForm()
          })
          .catch((err) => {
            this.$store.commit('ALERT', {
              color: 'error',
              text: err.response.data.msg,
            })
            this.closeForm()
            this.errorMessage = err.response.data.msg
          })
      }
      this.changePwd = false
    },

    /**
     * @description Limpia el contenido del dialogo
     */
    clearForm() {
      this.$v.$reset()
      ;(this.password = ''), (this.newPassword = ''), (this.confirmNewPassword = '')
    },

    /**
     * @description Cierra el dialogo de cambio de contraseña
     */
    closeForm() {
      ;(this.changePwd = false),
        (this.show1 = false),
        (this.show2 = false),
        (this.show3 = false),
        this.clearForm()
    },

    /**
     * @description Establece el modo de administrador en verdadero, y tarifas
     * como menú actual asi como el router en tarifas y la pestaña de tab en 3
     */
    tarifasSettings() {
      this.setAdminMode(true)
      this.tab = 3
    },

    /**
     * @description Establece el modo de administrador en verdadero, y transportadora
     * como menú actual asi como el router en flotilla y la pestaña de tab en 4
     */
    transportadoraSettings() {
      this.$store.commit('SET_MENU', 'transportadora')
      this.$router.push({ name: 'Flotilla' })
      this.setAdminMode(true)
      this.tab = 4
    },

    /**
     * @description Cierra la sesión del usuario
     */
    userLogout() {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          // if (process.env.VUE_APP_USE_SOCKET === true) {
          //   console.log('Desconectando socket')
          //   this.$socket.disconnect()
          // }
          this.$router.push({ name: 'Login' })
          this.$store.commit('SET_MENU', 'dashboard')
          this.setAdminMode(false)
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /**
     * @description Despliega el modulo de perfil de usuario
     */
    userProfile() {
      this.$router.push({ name: 'Perfil de Usuario' })
    },

    /**
     * @description Establece el modo de administrador en verdadero, y usuarios
     * como menú actual asi como el router en usuarios y la pestaña de tab en 5
     */
    usuariosSettings() {
      this.$store.commit('SET_MENU', 'usuarios')
      this.$router.push({ name: 'Usuarios' })
      this.setAdminMode(true)
      this.tab = 5
    },

    /**
     * @description Establece el modo de administrador en verdadero, y ventas
     * como menú actual asi como el router en ventas y la pestaña de tab en 6
     */
    ventasSettings() {
      this.$store.commit('SET_MENU', 'ventas')
      this.$router.push({ name: 'Boletos Ferry' })
      this.setAdminMode(true)
      this.tab = 6
    },

    // userGroups() {
    //   this.$router.push('/admon/grupos')
    // },

    ...mapMutations({
      setDrawer: 'SET_DRAWER',
      setAdminMode: 'SET_ADMIN_MODE',
    }),
  },
}
</script>
